<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 14:47:53
 * @LastEditTime: 2021-05-26 21:51:17
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="sidebar">
    <!-- 左侧二级菜单栏的组件封装 -->
    <div class="p-name">{{ parentTitle }}</div>
    <el-menu
      class="sidebar-el-menu"
      :default-active="toIndex()"
      @select="handleSelect"
    >
      <template v-for="item in items">
        <el-menu-item :index="item.index" :key="item.index">
          <!-- 需要图标的在 item 对象中加上属性 icon -->
          <!-- <i :class="item.icon"></i> -->
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>


<script>
export default {
  props: ['items'],
  data () {
    return {
      parentTitle: ''
    }
  },
  created () {
    this.parentTitle = this.$route.matched[1].meta.title;
  },
  methods: {
    // 根据路径绑定到对应的二级菜单，防止页面刷新重新跳回第一个
    toIndex () {
      return this.$route.path.split('/')[2];
    },
    // 切换菜单栏
    handleSelect (key) {
      let father = this.$route.path.split('/')[1]
      this.$router.push('/' + father + '/' + key)
    },

  },
  watch: {

  }
}
</script>

<style scoped lang='scss'>
/* 左侧菜单栏定位和位置大小设定 */
/* 左侧二级菜单项的样式 */
.sidebar {
  .p-name {
    color: #2b2b2b;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #bfbfbf;
  }
  .el-menu {
    border-right: none;
  }
  .el-menu-item {
    font-size: 14px !important;
    color: #2b2b2b !important;
    text-align: center;
    background: #fff;
    border-bottom: 1px solid #bfbfbf;
    text-align: left;
    padding: 0 8px !important;
    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 12px;
      background: #2b2b2b;
      vertical-align: middle;
      margin-right: 6px;
      border-radius: 2px;
    }
  }

  /* 左侧二级菜单选中时的样式 */
  .el-menu-item.is-active {
    color: #8f8160 !important;
    background: #fff !important;
    font-weight: bold;
    &::before {
      background: #8f8160;
    }
  }
  .el-menu-item,
  .el-submenu__title {
    height: 48px !important;
    line-height: 48px !important;
  }
  .el-menu-item:hover {
    background: #fff;
    color: #8f8160 !important;
  }
}
</style>